// TODO: Generate API from open-api script (setup in readme)
// Only Placeholder - should be generated with the api openApi script
import { NgModule } from '@angular/core';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [],
})
export class BitgoApiModule {}

// ------------- ------------- ------------- ------------- -------------
